import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { DataService } from './data.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {

  constructor(private dataService: DataService, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token: string | null = this.dataService.onGetCookie(environment.TOKEN_NAME);
    const refreshToken: string | null = this.dataService.onGetCookie(environment.REFRESH_TOKEN_NAME);

    if (token){
      request = this.cloneRequest(request, token);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => throwError(this.handdleError(error)) )
    );
  }

  private cloneRequest(request: HttpRequest<unknown>, token: string): HttpRequest<unknown>{
    return request.clone({
      setHeaders: {
        Authorization : `Bearer ${token}`
      }
    });
  }

  private handdleError(error: HttpErrorResponse): HttpErrorResponse{
    console.warn('Ocurrió Un Error', error);

    if (error.status === 401 && this.router.url !== '/auth/login'){
      this.dataService.onRemoveAll();
      // this.router.navigate(['/auth', 'login']);
      window.location.href = '/auth/login';
    }
    return error;
  }
}
