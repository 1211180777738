import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { IToken } from '../interfaces/i-token';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  tokenSite = environment.TOKEN_NAME;
  refreshTokenSite = environment.REFRESH_TOKEN_NAME;
  branchId = 'branchId';
  branchName = 'branchName';
  latitudeSite = 'latitudeSite';
  longitudeSite = 'longitudeSite';
  constructor(private cookieService: CookieService) { }

  onSaveToken(token: any): void{
    this.onSetCookie(this.tokenSite, token.token);
    this.onSetCookie(this.refreshTokenSite, token.refreshToken);
    this.onSetCookie(this.branchId, token.branchId);
    this.onSetCookie(this.branchName, token.branch.name);
    this.onSetCookie(this.latitudeSite, token.location.latitude);
    this.onSetCookie(this.longitudeSite, token.location.longitude);
    this.onSetCookie('watchCost', token.watchCost);
    this.onSetCookie('platformCost', token.platformCost);
    this.onSetCookie('showTag', token.showTag);

  }

  onGetCookie(name: string): string | null{
    return localStorage.getItem(name);
  }

  onRemoveCookie(name: string): void{
    localStorage.removeItem(name);
  }

  onRemoveAll(): void{
    localStorage.clear();
  }

  onIsLoggedIn(): boolean{
    return this.onGetCookie(environment.TOKEN_NAME) ? true : false;
  }

  onSetCookie2(name: string, content: string): void{
    localStorage.setItem(name, content);
  }

  private onSetCookie(name: string, content: string): void{
    localStorage.setItem(name, content);
  }
}
