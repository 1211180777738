// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiURL: 'http://localhost:3333',
  // apiUrlNotificator: 'http://127.0.0.1:3434',
  // WS: 'ws://localhost:3333',
  // CHAT_WS: 'ws://localhost:3434',
  // API_KEY_GM: 'AIzaSyD4t5TRftaTdO1jTjyLQszTj-7eHLLUfyA',
  // APP_VERSION : 'v1.1.2',
  // TOKEN_NAME : 'TokenDelivery',
  // REFRESH_TOKEN_NAME : 'RefreshTokenDelivery'
  apiURL: 'https://api.iyegoo.com.mx',
  apiUrlNotificator: 'https://notificador.iyegoo.com.mx',
  WS: 'wss://api.iyegoo.com.mx',
  CHAT_WS: 'wss://notificador.iyegoo.com.mx',
  API_KEY_GM: 'AIzaSyD4t5TRftaTdO1jTjyLQszTj-7eHLLUfyA',
  APP_VERSION : 'v1.5.7',
  TOKEN_NAME : 'TokenDelivery',
  REFRESH_TOKEN_NAME : 'RefreshTokenDelivery'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
